import React, { useEffect, useRef, useState }  from "react";
import './style.css';
import './fonts.css';
import WOW                                     from "wow.js";
import second                                  from '../assets/section/s2.png';

function SecondSection() {
    const sectionRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
    const [innerAnimation, setInnerAnimation] = useState(false);

    useEffect(() => {
        const wow = new WOW();
        wow.init();

        // IntersectionObserver to trigger animation when section is in view
        const currentSection = sectionRef.current;
        const observer = new IntersectionObserver(
            (entries) => {
                const entry = entries[0];
                if (entry.isIntersecting) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                    setInnerAnimation(false); // Reset inner animations when out of view
                }
            },
            { threshold: 0.5 }  // Trigger when 50% of the section is visible
        );

        if (currentSection) {
            observer.observe(currentSection);
        }

        return () => {
            if (currentSection) {
                observer.unobserve(currentSection);
            }
        };
    }, []);

    useEffect(() => {
        const container = sectionRef.current?.querySelector('.container');

        if (container) {
            container.addEventListener('animationend', () => {
                setInnerAnimation(true);  // Trigger inner animations
            });
        }

        return () => {
            if (container) {
                container.removeEventListener('animationend', () => {
                    setInnerAnimation(true);
                });
            }
        };
    }, [isVisible]);

    // Determine animation class based on visibility
    const animationClass = isVisible ? 'animate__bounceIn' : '';

    return (
        <section ref={sectionRef} className="container pt-5 my-5">
            <div className="row container justify-content-center">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center flex-column">
                    <h1 className={`poppins-bold mt-5 grey-text text-center mt-2 wow animate__animated ${animationClass}`}>
                        People. Innovation. Impact.
                    </h1>
                    <p className="poppins-regular text text-center mt-3">
                        Our people are our greatest asset. They drive our vision,
                        fueling progress and delivering sustainable value to our
                        businesses and communities. This is our commitment to
                        growth, resilience, and success.
                    </p>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
                    <img 
                        src={second} 
                        alt="block" 
                        className={`img-fluid wow animate__animated ${innerAnimation ? 'animate__zoomIn' : 'animate__zoomIn'}`} 
                        width="80%" 
                        data-wow-delay="0.5s"
                    />
                </div>
            </div>
        </section>
    );
}

export default SecondSection;
