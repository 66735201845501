import React, { useEffect, useRef, useState } from "react";
import 'animate.css/animate.min.css';
import WOW                                    from "wow.js";
import './style.css';
import './fonts.css';
import s1                                     from '../assets/section/s1.png';



function FirstSection() {
    const sectionRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
    const [scrollDirection, setScrollDirection] = useState(null);  // Track the scroll direction
    const [innerAnimation, setInnerAnimation] = useState(false);   // Control inner animations

    useEffect(() => {
        const wow = new WOW();
        wow.init();

        let lastScrollY = window.pageYOffset;

        // Detect scroll direction
        const updateScrollDirection = () => {
            const currentScrollY = window.pageYOffset;
            if (currentScrollY > lastScrollY) {
                setScrollDirection('down');
            } else {
                setScrollDirection('up');
            }
            lastScrollY = currentScrollY;
        };

        window.addEventListener('scroll', updateScrollDirection);

        // Capture sectionRef.current in a local variable
        const currentSection = sectionRef.current;

        // IntersectionObserver to trigger animation based on scroll direction
        const observer = new IntersectionObserver(
            (entries) => {
                const entry = entries[0];
                if (entry.isIntersecting) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                    setInnerAnimation(false);  // Reset inner animations when section is out of view
                }
            },
            { threshold: 0.2 }  // Trigger when 20% of the section is visible
        );

        if (currentSection) {
            observer.observe(currentSection);
        }

        return () => {
            window.removeEventListener('scroll', updateScrollDirection);
            if (currentSection) {
                observer.unobserve(currentSection);
            }
        };
    }, []);

    useEffect(() => {
        const container = sectionRef.current?.querySelector('.background-container');

        // Trigger inner animations when the container animation ends
        if (container) {
            container.addEventListener('animationend', () => {
                setInnerAnimation(true);  // Trigger inner animations
            });
        }

        return () => {
            if (container) {
                container.removeEventListener('animationend', () => {
                    setInnerAnimation(true);
                });
            }
        };
    }, [isVisible]);

    // Determine animation class based on visibility and scroll direction
    const animationClass = isVisible
        ? scrollDirection === 'down'
            ? 'animate__fadeInUp'  // Animate from top when scrolling down
            : 'animate__fadeInDown'    // Animate from bottom when scrolling up
        : '';

    return (
        <section ref={sectionRef} className="container my-2 d-flex flex-row justify-content-center align-items-center">
            {/* <div className="overlay-text">
                <span className="letter">T</span>
                <span className="letter">G</span>
                <span className="letter">P</span>
                <span className="letter">S</span>
            </div> */}

            <div className="container">
                <div className={`row background-container mb-5 wow animate__animated ${animationClass}`}>
                    {/* H1 with dynamic animation */}
                    <h1 className={`poppins-bold grey-text mb-2 wow animate__animated ${innerAnimation ? 'animate__bounceIn' : ''}`}>
                        THE GROUP
                    </h1>
                    <h4 className={`poppins-bold wow animate__animated ${innerAnimation ? 'animate__bounceIn' : ''}`} style={{ color: '#FA4529' }}>
                            Decoding Green Future
                        </h4>
                    <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
                        {/* H4 with bounceIn animation */}
                        
                        <img src={s1} alt="block" className="img-fluid" width="90%" />
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                        {/* P with fadeInUp animation */}
                        <p className="poppins-regular text animate__animated">
                            At <span className="poppins-bold">Thai Green Power Solution</span>, we are a global conglomerate at the forefront of revolutionising sustainable energy and decentralised ecosystems. Our diverse range of products and services, all rooted in renewable energy and green technology, drive our mission to create a sustainable and eco-friendly future. Through strategic partnerships with governments, industry leaders, and global organisations, we are committed to advancing a cleaner, more sustainable planet while shaping the future of decentralised and eco-conscious solutions.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FirstSection;
